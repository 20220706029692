




















































































































































































































import { Vue, Component } from "vue-property-decorator";
import { namespace } from "vuex-class";
import Vuelidate from "vuelidate";
import { required } from "vuelidate/lib/validators";
import VModal from "vue-js-modal";

import {
  CustomTable,
  CustomInput,
  Dropdown,
  SvgIcon,
  Loader,
} from "@components";
import {
  FeeTypeMap,
  FeeType,
  Responsible,
  ResponsibleMap,
  Status,
  StatusMap,
  Fee,
} from "@/proto/fee/all_pb";

Vue.use(VModal);
Vue.use(Vuelidate);

const configModule = namespace("config");

@Component({
  components: {
    CustomTable,
    CustomInput,
    Dropdown,
    SvgIcon,
    Loader,
  },
  validations: {
    selectedFee: {
      name: {
        required,
      },
      feetype: {
        required,
      },
      cost: {
        required,
      },
      responsible: {
        required,
      },
      status: {
        required,
      },
    },
  },
})
export default class FeeManagement extends Vue {
  @configModule.Action("getFeeList") getFeeList!: () => Array<Fee.AsObject>;
  @configModule.Action("updateFee") updateFee!: (fee: Fee.AsObject) => void;

  private feeList: Array<any> = [];
  private selectedFee: Fee.AsObject | null = null;
  private updateError: string | null = null;
  private loading = false;

  get COLUMNS() {
    const columns = [
      {
        field: "name",
        label: "Service",
        sortable: false,
        width: "50px",
      },
      {
        field: "type",
        label: "Fee Type",
        sortable: false,
        width: "50px",
      },
      {
        field: "fee",
        label: "Fee",
        sortable: false,
        width: "50px",
      },
      {
        field: "responsible",
        label: "Responsible for Fee",
        sortable: false,
        width: "70px",
      },
      {
        field: "status",
        label: "Status",
        sortable: false,
        width: "50px",
      },
      {
        field: "options",
        label: "Actions",
        sortable: false,
        width: "20px",
      },
    ];

    return columns;
  }

  getTypeLabel(type: FeeTypeMap[keyof FeeTypeMap]) {
    switch (type) {
      case FeeType.FIXED:
        return "Fixed Fee";
      case FeeType.PERCENTAGE:
        return "Percentage";
      default:
        return "Unknown";
    }
  }

  getStatusLabel(status: StatusMap[keyof StatusMap]) {
    switch (status) {
      case Status.ACTIVE:
        return "Active";
      case Status.INACTIVE:
        return "Inactive";
      default:
        return "Unknown";
    }
  }

  getResponsibleLabel(responsible: ResponsibleMap[keyof ResponsibleMap]) {
    switch (responsible) {
      case Responsible.SENDER:
        return "Sender";
      case Responsible.RECEIVER:
        return "Receiver";
      default:
        return "Unknown";
    }
  }

  get feeTypeList() {
    return [
      {
        label: this.getTypeLabel(FeeType.FIXED),
        value: FeeType.FIXED,
      },
      {
        label: this.getTypeLabel(FeeType.PERCENTAGE),
        value: FeeType.PERCENTAGE,
      },
    ];
  }

  get feeHandlerList() {
    return [
      {
        label: this.getResponsibleLabel(Responsible.SENDER),
        value: Responsible.SENDER,
      },
      {
        label: this.getResponsibleLabel(Responsible.RECEIVER),
        value: Responsible.RECEIVER,
      },
    ];
  }

  get feeStatusList() {
    return [
      {
        label: this.getStatusLabel(Status.ACTIVE),
        value: Status.ACTIVE,
      },
      {
        label: this.getStatusLabel(Status.INACTIVE),
        value: Status.INACTIVE,
      },
    ];
  }

  get btnLabel() {
    return this.loading ? "Updating" : "Update";
  }

  async saveFee() {
    this.$v.$touch();
    if (this.$v.$invalid) return;
    this.loading = true;

    try {
      await this.updateFee(this.selectedFee as Fee.AsObject);
      await this.fetchList();
    } catch (error) {
      const err = error as Error;
      this.updateError = err.message;
    } finally {
      this.hideFeeModal();
      this.loading = false;
    }
  }

  showFeeModal(fee: any) {
    this.selectedFee = {
      service: fee.service,
      name: fee.name,
      feetype: fee.feetype,
      cost: fee.cost,
      responsible: fee.responsible,
      status: fee.status,
    };
    this.$modal.show("fee-modal");
  }

  hideFeeModal() {
    this.$modal.hide("fee-modal");
  }

  async fetchList() {
    const feeList = await this.getFeeList();

    this.feeList = feeList.map((fee) => {
      return {
        service: fee.service,
        name: fee.name,
        feetype: fee.feetype,
        cost: fee.cost,
        responsible: fee.responsible,
        status: fee.status,
        statusClass: `fee-${this.getStatusLabel(fee.status).toLowerCase()}`,
      };
    });
  }

  mounted() {
    this.fetchList();
  }
}
